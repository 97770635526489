
<template>
  <div class="w-full">
    <div class="d-flex justify-center w-full flex-column">
      <div
        v-if="showDropZone"
        ref="dropZone"
        class="drop-zone"
        aria-label="Drop zone for images"
        @dragover.prevent
        @dragenter.prevent
        @drop="onFileDrop"
        @click="onUploadClick"
      >
        <p class="drop-zone__prompt">
          {{ $t('labels.image-upload.drop-image') }}
        </p>
      </div>
      <v-btn
        v-else
        class="px-6 w-full text-caption font-weight-bold"
        :disabled="disabled"
        @click="onUploadClick"
      >
        <v-icon
          small
          class="pr-2"
        >
          mdi-upload
        </v-icon>
        <span>{{ $t('buttons.image-upload.cta') }}</span>
      </v-btn>
      <input
        ref="FileInput"
        type="file"
        :accept="accept"
        style="display: none;"
        @change="onFileSelect"
      >
    </div>
    <v-dialog
      v-if="dialogWidth"
      v-model="dialog"
      :width="dialogWidth"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card class="d-flex flex-column wrapper">
        <v-card-text class="pa-4 cropper">
          <div
            v-if="processing"
            class="d-flex flex-column justify-center align-center text-h5 text-center"
          >
            <v-progress-circular
              :size="150"
              :width="10"
              indeterminate
              color="primary"
              class="ma-12"
            />
            {{ $t('alerts.image-upload.in-progress', { readablePlatformName }) }}
          </div>

          <VueCropper
            v-show="file && !processing"
            ref="cropper"
            :src="file"
            :aspect-ratio="ratio"
            :cropmove="onCropmove"
            :auto-crop-area="autoCropArea"
            :zoomable="false"
            :alt="$t('labels.image-upload.selected-image')"
            @ready="onCropmove"
          />

          <div
            v-if="!processing"
            class="pt-6"
          >
            <v-alert
              v-if="hasErrors"
              type="error"
            >
              <span v-if="isImageToSmall">{{ $t('alerts.image-upload.error-too-small', { minWidth, minHeight }) }}<br></span>
              <span v-if="isImageToBig">{{ $t('alerts.image-upload.error-too-big', { maxWidth, maxHeight }) }}<br></span>
              <span v-if="isImageFileToBig">{{ $t('alerts.image-upload.error-file', { max: maxImageFileSize/(1024 *1024) }) }}<br></span>
            </v-alert>
            <v-alert
              v-else
              type="info"
              class="text-body-2"
            >
              {{ $t('alerts.image-upload.info-text') }}
            </v-alert>
          </div>

          <div
            v-if="isRemovingBackground"
            class="d-flex justify-center mt-4 mb-4 align-center"
          >
            <v-progress-circular
              class="mr-2"
              width="3"
              size="20"
              color="primary"
              indeterminate
            />
            {{ $t('alerts.image-upload.is-removing') }}
          </div>

          <v-checkbox
            v-if="askForImageRights"
            v-model="hasImageRights"
            class="mx-4"
            :label="$t('alerts.image-upload.confirm-rights')"
          />
        </v-card-text>

        <v-card-actions class="justify-space-between">
          <v-btn
            text
            @click="dialog = false"
          >
            {{ $t('labels.cancel') }}
          </v-btn>
          <v-btn
            text
            color="primary"
            :disabled="hasErrors || (askForImageRights && !hasImageRights)"
            @click="saveImage(), (dialog = false)"
          >
            {{ $t('labels.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import createClient from '@/clients/api'
import branding from '@/mixins/branding'

export default {
  components: { VueCropper },
  mixins: [branding],
  props: {
    displayImage: {
      type: Object,
      default: () => ({})
    },
    save: {
      type: Function,
      default: () => ({})
    },
    minWidth: {
      type: Number,
      default: 0
    },
    minHeight: {
      type: Number,
      default: 0
    },
    maxWidth: {
      type: Number,
      default: 12000
    },
    maxHeight: {
      type: Number,
      default: 12000
    },
    accept: {
      type: String,
      default: 'image'
    },
    autoCropArea: {
      type: Number,
      default: 0.9
    },
    ratio: {
      type: Number,
      default: 0
    },
    minOpacity: {
      type: Number,
      default: 0
    },
    askForImageRights: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showDropZone: {
      type: Boolean,
      default: false
    },
    // 12 MB Limit from remove.bg
    maxImageFileSize: {
      type: Number,
      default: 12582912
    }
  },
  data () {
    return {
      mimeType: '',
      file: '',
      image: '',
      dialog: false,
      files: '',
      processing: false,
      dialogWidth: undefined,
      cropWidth: 0,
      cropHeight: 0,
      imageWidth: 0,
      imageHeight: 0,
      imageOpacity: 0,
      imageFileSize: 0,
      hasImageRights: false,
      isRemovingBackground: false,
      hasRemovedBackground: false
    }
  },
  computed: {
    isImageToSmall () {
      return this.imageWidth < this.minWidth || this.imageHeight < this.minHeight
    },
    isImageToBig () {
      return this.imageWidth > this.maxWidth || this.imageHeight > this.maxHeight
    },
    isImageOpacityToLow () {
      return this.imageOpacity < this.minOpacity
    },
    isImageFileToBig () {
      return this.imageFileSize > this.maxImageFileSize
    },
    hasErrors () {
      return this.isImageFileToBig || this.isImageToSmall || this.isImageToBig
    }
  },
  watch: {
    dialog () {
      this.dialogWidth = undefined
    }
  },
  methods: {
    async optimize (base64image) {
      var formdata = new FormData()
      formdata.append('image', base64image)
      formdata.append('width', this.minWidth)
      formdata.append('height', this.minHeight)
      formdata.append('transparent', this.isImageOpacityToLow)
      const client = await createClient()
      const { data } = await client.post('/image-ai/optimize', formdata)
      const file = new File([data], 'image.png', { type: 'image/png' })
      this.$tracking.event('Settings', 'Upload', 'AI-optimized')

      return this.convertToBase64(file)
    },
    convertToBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => {
          const base64data = reader.result
          resolve(base64data)
        }
        reader.onerror = reject
        reader.readAsDataURL(file)
      })
    },
    onCropmove (event) {
      var data = this.$refs.cropper.getData()
      if (data.width < this.minWidth) {
        event.preventDefault()
        data.width = this.minWidth
        this.$refs.cropper.setData(data)
      }
      if (data.height < this.minHeight) {
        event.preventDefault()
        data.height = this.minHeight
        this.$refs.cropper.setData(data)
      }
      this.ensureImageSize(data)
      this.cropWidth = Math.round(data.width)
      this.cropHeight = Math.round(data.height)
    },
    ensureImageSize (data) {
      if (data.y < 0) {
        data.y = 0
      }
      if (data.height + data.y > this.imageHeight) {
        const offset = (data.height + data.y - this.imageHeight)
        data.y = data.y - offset
        if (data.y < 0) {
          data.y = 0
          data.height = data.height - offset
        }
      }
      this.$refs.cropper.setData(data)
    },
    saveImage () {
      var data = this.$refs.cropper.getData()
      this.ensureImageSize(data)
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        this.$emit('input', blob)
        this.save(blob)
      }, this.mimeType)
      this.$tracking.event('Settings', 'Clicked', 'Image-save')
    },
    onUploadClick () {
      this.$tracking.event('Settings', 'Clicked', 'Upload Image')
      this.hasRemovedBackground = false
      this.$refs.FileInput.value = null
      this.$refs.FileInput.click()
    },
    onFileDrop (event) {
      event.preventDefault()
      this.$refs.FileInput.value = null
      this.onFileSelect({ target: { files: event.dataTransfer.files } })
    },
    onFileSelect (e) {
      const file = e.target.files[0]
      this.imageFileSize = file.size
      this.mimeType = file.type
      if (typeof FileReader === 'function') {
        this.dialog = true
        const reader = new FileReader()
        reader.onload = (event) => {
          this.file = event.target.result
          const img = new Image()
          img.onload = async () => {
            const ratio = img.width / img.height
            this.imageOpacity = this.opacityRatio(img)
            this.imageWidth = img.width
            this.imageHeight = img.height
            this.dialogWidth = Math.max(600 * ratio, 600)
            if (this.isImageToSmall || this.isImageOpacityToLow) {
              this.processing = true
              try {
                this.file = await this.optimize(file)
                img.src = this.file
                this.$refs.cropper.replace(this.file)
                var data = this.$refs.cropper.getData()
                this.ensureImageSize(data)
              } finally {
                this.processing = false
              }
            }
          }
          img.src = event.target.result
          if (this.$refs.cropper) {
            this.$refs.cropper.replace(this.file)
          }
        }
        reader.readAsDataURL(file)
      } else {
        alert(this.$t('alerts.image-upload.error'))
      }
    },
    opacityRatio (image) {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      canvas.width = image.width
      canvas.height = image.height
      context.drawImage(image, 0, 0)
      const data = context.getImageData(0, 0, canvas.width, canvas.height).data
      let opacity = 0
      for (let i = 0; i < data.length; i += 4) {
        opacity += data[i + 3]
      }
      return 1 - (opacity / 255) / (data.length / 4)
    }
  }
}
</script>

<style scoped>
.cropper > div {
  max-height: 60vh;
}

.drop-zone {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  border: 2px dashed #aaa;
  border-radius: 10px;
  background-color: #f8f8f8;
  transition: border .3s ease-in-out;
}

.drop-zone__prompt {
  text-align: center;
  color: #aaa;
  font-size: 1.5rem;
}

.drop-zone:hover {
  border: 2px dashed #000;
}

@media (min-width: 960px) {
  .cropper {
    flex-grow: 1 !important;
  }
}
@media (max-width: 960px) {
  .wrapper {
    padding-bottom: 70px !important;
  }
}

</style>
